// src/components/Approach.js
import React from 'react';

const Approach = () => {
  return (
    <section
      id="approach"
      className="dtr-section dtr-py-100 bg-light-blue parallax"
      style={{ backgroundImage: 'url(/assets/images/section-bg2.png)' }}
    >
      <div className="container">
        {/* heading */}
        <div className="row dtr-mb-50">
          <div className="col-12 text-center">
            <h2>Nuestro Enfoque de Trabajo</h2>
            <div className="dtr-styled-divider divider-center bg-light-green"></div>
          </div>
        </div>
        {/* content */}
        <div className="row">
          <div className="col-12">
            {/* FAQ item 1 */}
            <div className="faq-item">
              <h4 className="faq-heading">Análisis y Presupuesto Personalizado</h4>
              <div className="faq-content">
                Elaboramos un presupuesto detallado que define el alcance del
                proyecto, los costos y un cronograma realista.
              </div>
            </div>
            {/* FAQ item 2 */}
            <div className="faq-item">
              <h4 className="faq-heading">Demostración Gratuita Inicial</h4>
              <div className="faq-content">
                Ofrecemos una demostración gratuita que ilustra la
                funcionalidad básica del sistema solicitado para nuevos desarrollos.
              </div>
            </div>
            {/* FAQ item 3 */}
            <div className="faq-item">
              <h4 className="faq-heading">Desarrollo Iterativo y Entregas Semanales</h4>
              <div className="faq-content">
                Presentamos avances regularmente para que puedas monitorear el
                progreso y proporcionar retroalimentación continua.
              </div>
            </div>
            {/* FAQ item 4 */}
            <div className="faq-item">
              <h4 className="faq-heading">Modelo de Pago por Entregables</h4>
              <div className="faq-content">
                Los pagos se realizan a medida que se entregan las
                funcionalidades desarrolladas, garantizando transparencia.
              </div>
            </div>
            {/* FAQ item 5 */}
            <div className="faq-item">
              <h4 className="faq-heading">Entrega Progresiva de Código y Documentación</h4>
              <div className="faq-content">
                Entregamos el código fuente y la documentación completa
                conforme avanzamos.
              </div>
            </div>
            {/* FAQ item 6 */}
            <div className="faq-item">
              <h4 className="faq-heading">Despliegue en Entornos Temporales</h4>
              <div className="faq-content">
                Proporcionamos URLs temporales para que interactúes con las
                funcionalidades desarrolladas hasta el momento.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Approach;
