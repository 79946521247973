import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore'; // Firestore
import { getAnalytics, logEvent } from 'firebase/analytics'; // Analytics
import CryptoJS from 'crypto-js'; // Para desencriptar

import './assets/css/bootstrap.min.css';
import './assets/fonts/iconfonts.css';
import './assets/css/plugins.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/color.css';

import Preloader from './components/Preloader';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import StepsToStart from './components/StepsToStart';
import Services from './components/Services';
import Approach from './components/Approach';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Unsubscribe from './components/Unsubscribe';
import NotFound from './components/NotFound';

// Clave secreta utilizada en Python para el cifrado (debe ser idéntica)
const SECRET_KEY = '32CharacterLongSecretKey12345678';

function App() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Captura los parámetros UTM y "email" de la URL
    if (urlParams.has('utm_source') || urlParams.has('utm_medium') || urlParams.has('utm_campaign')) {
      // Guarda los parámetros UTM en localStorage
      localStorage.setItem('utm_source', urlParams.get('utm_source') || '');
      localStorage.setItem('utm_medium', urlParams.get('utm_medium') || '');
      localStorage.setItem('utm_campaign', urlParams.get('utm_campaign') || '');

      // Limpiar los parámetros UTM de la URL
      const cleanUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({}, document.title, cleanUrl);
    }
    // Verifica si la URL contiene el parámetro `email`
    if (urlParams.has('email')) {
      const encryptedEmail = urlParams.get('email'); // Obtiene el email cifrado de la URL

      // Función para desencriptar el correo electrónico
      const decryptEmail = (encryptedEmail) => {
        try {
          // Convertir Base64 seguro para URL a Base64 estándar
          let base64 = encryptedEmail.replace(/-/g, '+').replace(/_/g, '/');
          // Agregar relleno '=' para que la longitud sea múltiplo de 4
          while (base64.length % 4) {
            base64 += '=';
          }

          // Desencriptar
          const decrypted = CryptoJS.AES.decrypt(
            base64,
            CryptoJS.enc.Utf8.parse(SECRET_KEY),
            {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7,
            }
          );

          return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
          console.error('Error al desencriptar el correo:', error);
          return null;
        }
      };

      // Desencripta el correo
      const decryptedEmail = decryptEmail(encryptedEmail);

      if (decryptedEmail) {

        // Actualiza Firestore con los datos del usuario
        const db = getFirestore();
        const updateFirestore = async () => {
          try {
            const docRef = doc(db, 'companies', decryptedEmail); // Busca el documento en la colección `companies`

            // Configura la hora de Caracas (UTC-4)
            const caracasTime = new Date();
            caracasTime.setHours(caracasTime.getHours());

            // Actualiza los campos en Firestore
            await updateDoc(docRef, {
              hasLink: true,
              clicked: true,
              clickedAt: caracasTime,
            });

          } catch (error) {
            console.error('Error al actualizar Firestore:', error);
          }
        };

        updateFirestore(); // Ejecuta la función para actualizar Firestore
      } else {
        console.error('No se pudo desencriptar el correo.');
      }
    }

    // Configuración de Firebase Analytics
    const analytics = getAnalytics();

    // Manejo de eventos de scroll para enviar datos de profundidad
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight * 0.25) {
        logEvent(analytics, 'scroll_depth', { depth: '25%' });
      }
      if (scrollPosition >= pageHeight * 0.5) {
        logEvent(analytics, 'scroll_depth', { depth: '50%' });
      }
      if (scrollPosition >= pageHeight * 0.75) {
        logEvent(analytics, 'scroll_depth', { depth: '75%' });
      }
      if (scrollPosition >= pageHeight) {
        logEvent(analytics, 'scroll_depth', { depth: '100%' });
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup: remove event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Router>
      <div id="dtr-wrapper" className="clearfix">
        <Preloader />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <div id="dtr-main-content">
                <HeroSection />
                <AboutUs />
                <StepsToStart />
                <Services />
                <Approach />
                <Contact />
              </div>
            }
          />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
