import React from 'react';

const HeroSection = () => {
  return (
    <section
      id="home"
      className="dtr-section dtr-section-with-bg"
      style={{ backgroundImage: 'url(/assets/images/hero-bg2.jpg)' }}
    >
      {/* overlay */}
      <div className="dtr-overlay dtr-overlay-green"></div>
      <div className="container dtr-overlay-content hero-section-top-padding">
        {/* row */}
        <div className="row d-flex align-items-center dtr-pt-100 dtr-pb-50">
          {/* text column */}
          <div className="col-12 col-md-6">
            <h1 className="color-white">
              Innovamos para que tu empresa nunca deje de crecer
            </h1>
            <p className="text-size-md dtr-mt-30 color-white">
              Soluciones tecnológicas a medida que impulsan la eficiencia y el
              crecimiento de las organizaciones.
            </p>
            <p>
              <a
                href="#contact"
                className="dtr-btn btn-white dtr-scroll-link dtr-px-50 dtr-mt-20 dtr-mr-10"
              >
                Contáctanos
              </a>
              <a
                href="#services"
                className="dtr-btn dtr-px-50 dtr-mt-20"
              >
                Nuestros Servicios
              </a>
            </p>
          </div>
          {/* image column */}
          <div className="col-12 col-md-6">
            <img
              src="/assets/images/hero-img3.png"
              alt="Innovative technology solutions" // Descripción adecuada para la imagen
              className="dtr-mb-minus50 animated-img"
            />
          </div>
        </div>
      </div>
      <div
        className="dtr-section-with-bg hero-svg-bg-absolute"
        style={{
          backgroundImage: 'url(/assets/images/landing3-hero.svg)',
          width: '100%',
          height: '200px',
        }}
      ></div>
    </section>
  );
};

export default HeroSection;
