// src/components/StepsToStart.js
import React from 'react';

const StepsToStart = () => {
  return (
    <section
      className="dtr-section dtr-pt-100 dtr-pb-70 dtr-section-with-bg bg-light-blue parallax"
      style={{ backgroundImage: 'url(/assets/images/section-bg1.png)' }}
    >
      <div className="container">
        {/* heading */}
        <div className="row">
          <div className="col-12 text-center">
            <h2>Pasos para Comenzar a Recibir Nuestros Servicios</h2>
            <div className="dtr-styled-divider divider-center bg-light-green"></div>
          </div>
        </div>
        {/* steps */}
        <div className="row dtr-mt-50">
          {/* step 1 */}
          <div className="col-12 col-md-4">
            <div className="dtr-feature dtr-feature-top feature-has-box feature-has-shadow dtr-rounded-corner-xl text-center bg-white">
              <div className="dtr-feature-img">
                <i
                  className="icon-user-edit"
                  style={{ fontSize: '48px', color: '#28A745' }}
                ></i>
              </div>
              <div className="dtr-feature-content">
                <h3>Agenda tu Reunión Inicial</h3>
                <p>
                  El primer paso es conocernos. Agenda una reunión gratuita
                  donde podremos conversar sobre tus necesidades tecnológicas.
                </p>
              </div>
            </div>
          </div>
          {/* step 2 */}
          <div className="col-12 col-md-4">
            <div className="dtr-feature dtr-feature-top feature-has-box feature-has-shadow dtr-rounded-corner-xl text-center bg-white">
              <div className="dtr-feature-img">
                <i
                  className="icon-lightbulb"
                  style={{ fontSize: '48px', color: '#28A745' }}
                ></i>
              </div>
              <div className="dtr-feature-content">
                <h3>Presenta tu Idea o Proyecto</h3>
                <p>
                  Durante la reunión, nos contarás sobre tu proyecto o idea. Te
                  ayudaremos a perfilar y estructurar tu visión.
                </p>
              </div>
            </div>
          </div>
          {/* step 3 */}
          <div className="col-12 col-md-4">
            <div className="dtr-feature dtr-feature-top feature-has-box feature-has-shadow dtr-rounded-corner-xl text-center bg-white">
              <div className="dtr-feature-img">
                <i
                  className="icon-file-alt"
                  style={{ fontSize: '48px', color: '#28A745' }}
                ></i>
              </div>
              <div className="dtr-feature-content">
                <h3>Recibe el Presupuesto</h3>
                <p>
                  Realizaremos un análisis detallado y te entregaremos una
                  propuesta personalizada con plan de trabajo, cronograma y
                  presupuesto.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsToStart;
