import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <footer
      id="dtr-footer"
      className="dtr-section-with-bg"
      style={{ backgroundImage: 'url(/assets/images/footer-bg.png)' }}
    >
      <div className="container">
        <div className="row">
          {/* column 1 */}
          <div className="col-12 col-sm-6 col-lg-5 dtr-mb-30">
            <a href={isHome ? "#home" : "/#home"} className="d-block dtr-mb-15">
  
        <span className="logo-text">
  ARAUL<span className="logo-highlight">TECH</span>
</span>




            </a>
            <p>
              En AraulTech, creemos que la tecnología es un catalizador para el
              cambio y el crecimiento. Estamos emocionados por la posibilidad de
              colaborar contigo para transformar tus ideas en soluciones
              tecnológicas tangibles que generen valor y ventajas competitivas.
            </p>
            <p>&copy; 2024 AraulTech. Todos los derechos reservados.</p>
          </div>
          {/* column 2 */}
          <div className="col-12 col-sm-6 col-lg-3 dtr-mb-30">
            <h4 className="color-green">Empresa</h4>
            <ul className="dtr-list-simple">
              <li>
                <a href={isHome ? "#about-us" : "/#about-us"} className="dtr-styled-link">
                  Quiénes Somos
                </a>
              </li>
              <li>
                <a href={isHome ? "#services" : "/#services"} className="dtr-styled-link">
                  Servicios
                </a>
              </li>
              <li>
                <a href={isHome ? "#approach" : "/#approach"} className="dtr-styled-link">
                  Enfoque
                </a>
              </li>
              <li>
                <a href={isHome ? "#contact" : "/#contact"} className="dtr-styled-link">
                  Contacto
                </a>
              </li>
            </ul>
          </div>
          {/* column 3 */}
          <div className="col-12 col-sm-6 col-lg-4 dtr-mb-30">
            <h4 className="color-green">Redes</h4>
            <ul className="dtr-list-simple">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61568404225517" className="dtr-styled-link">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/araultech" className="dtr-styled-link">
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
