// src/components/AboutUs.js
import React from 'react';

const AboutUs = () => {
  return (
    <section id="about-us" className="dtr-section dtr-py-80">
      <div className="container">
        {/* row */}
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="color-green">Quiénes Somos</h2>
            <p>
<strong>AraulTech</strong> es una empresa especializada en el desarrollo de software a medida, que ofrece soluciones tecnológicas personalizadas para cada cliente. Utilizamos las tecnologías más avanzadas para crear nuevos proyectos y nos adaptamos a herramientas y plataformas existentes para mejorar y actualizar proyectos ya en marcha. Además, implementamos las últimas innovaciones en{' '}
<strong>inteligencia artificial</strong> y nos especializamos en{' '}
<strong>automatización de procesos</strong>, transformando desafíos empresariales en valiosas oportunidades tecnológicas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
