// src/components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{ padding: '100px', textAlign: 'center' }}>
      <h1>Página No Encontrada</h1>
      <p>Lo sentimos, la página que estás buscando no existe.</p>
      <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Volver al Inicio</a>
    </div>
  );
};

export default NotFound;
