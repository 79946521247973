// src/components/Preloader.js
import React from 'react';

const Preloader = () => {
  return (
    <div className="dtr-preloader">
      <div className="dtr-preloader-inner">
        <div className="dtr-preloader-img"></div>
      </div>
    </div>
  );
};

export default Preloader;
