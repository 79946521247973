// src/components/Services.js
import React from 'react';

const Services = () => {
  return (
    <section id="services" className="dtr-section dtr-py-80">
      <div className="container">
        {/* heading */}
        <div className="row dtr-mb-50">
          <div className="col-12 text-center">
            <h2 className="color-green">Nuestros Servicios</h2>
            <div className="dtr-styled-divider divider-center bg-light-green"></div>
          </div>
        </div>
        {/* content */}
        <div className="row">
          {/* accordion */}
          <div className="col-12 col-md-6">
            <div className="accordion" id="servicesAccordion">
              {/* item 1 */}
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <i className="icon-code color-green"></i> Desarrollo de
                      Sistemas Personalizados
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#servicesAccordion"
                >
                  <div className="card-body">
                    Creamos aplicaciones y sistemas adaptados a las necesidades
                    específicas de cada cliente.
                  </div>
                </div>
              </div>

              {/* item 2 */}
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <i className="icon-sync color-green"></i> Actualización y
                      Mejora de Proyectos Existentes
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#servicesAccordion"
                >
                  <div className="card-body">
                    Somos expertos en revitalizar proyectos ya desarrollados en
                    otras tecnologías.
                  </div>
                </div>
              </div>

              {/* item 3 */}
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <i className="icon-database color-green"></i>{' '}
                      Automatización de Procesos
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#servicesAccordion"
                >
                  <div className="card-body">
                    Aplicamos soluciones que optimizan y automatizan tareas
                    repetitivas.
                  </div>
                </div>
              </div>

              {/* item 4 */}
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <i className="icon-atom color-green"></i> Inteligencia Artificial
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#servicesAccordion"
                >
                  <div className="card-body">
                    Implementamos modelos de IA ya existentes para optimizar la toma de decisiones y mejorar los procesos empresariales.
                  </div>
                </div>
              </div>

              {/* item 5 */}
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <i className="icon-headset color-green"></i> Consultoría y
                      Asesoramiento Tecnológico
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#servicesAccordion"
                >
                  <div className="card-body">
                    Brindamos asesoría en la adopción de tecnologías modernas y
                    prácticas de desarrollo eficientes.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* image */}
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <img
              src="/assets/images/screen-1.png"
              alt="Nuestros Servicios"
              className="img-fluid"
              style={{
                maxWidth: '80%',
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
