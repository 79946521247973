//Header.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuButtonClick = () => {
    setMenuOpen(!menuOpen);
  };

  // Verifica si estás en la ruta raíz
  const isHome = location.pathname === '/';

  return (
    <>
      {/* Small Devices Header */}
      <div className="dtr-responsive-header fixed-top">
        <div className="container">
          {/* small devices logo */}
          <a href="/">
        <span className="logo-text">
  ARAUL<span className="logo-highlight">TECH</span>
</span>


          </a>
          {/* menu button */}
          <button
            id="dtr-menu-button"
            className={`dtr-hamburger ${menuOpen ? 'open' : ''}`}
            type="button"
            onClick={handleMenuButtonClick}
          >
            <span className="dtr-hamburger-lines-wrapper">
              <span className="dtr-hamburger-lines"></span>
            </span>
          </button>
        </div>
        {menuOpen && (
          <div className="dtr-responsive-header-menu">
            <ul className="dtr-scrollspy navbar-nav dtr-nav dark-nav-on-load dark-nav-on-scroll">
              <li className="nav-item">
                <a className="nav-link active" href={isHome ? "#home" : "/#home"}>
                  Inicio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={isHome ? "#about-us" : "/#about-us"}>
                  Quiénes Somos
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={isHome ? "#services" : "/#services"}>
                  Servicios
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={isHome ? "#approach" : "/#approach"}>
                  Enfoque
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={isHome ? "#contact" : "/#contact"}>
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* Header */}
      <header id="dtr-header-global" className="fixed-top bg-white">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            {/* header left */}
            <div className="dtr-header-left">
              {/* logo */}
              <a className="logo-default dtr-scroll-link" href={isHome ? "#home" : "/#home"}>
                <span className="logo-text">
                  ARAUL<span className="logo-highlight">TECH</span>
                </span>
              </a>
              <a className="logo-alt dtr-scroll-link" href={isHome ? "#home" : "/#home"}>
                <span className="logo-text">
                  ARAUL<span className="logo-highlight">TECH</span>
                </span>
              </a>
            </div>
            {/* menu */}
            <div className="main-navigation navbar navbar-expand-lg ml-auto">
              <ul className="dtr-scrollspy navbar-nav dtr-nav dark-nav-on-load dark-nav-on-scroll">
                <li className="nav-item">
                  <a className="nav-link active" href={isHome ? "#home" : "/#home"}>
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={isHome ? "#about-us" : "/#about-us"}>
                    Quiénes Somos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={isHome ? "#services" : "/#services"}>
                    Servicios
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={isHome ? "#approach" : "/#approach"}>
                    Enfoque
                  </a>
                </li>
              </ul>
            </div>
            {/* contact button */}
            <div className="dtr-header-right">
              <a
                href={isHome ? "#contact" : "/#contact"}
                className="dtr-btn btn-green dtr-scroll-link dtr-px-50"
              >
                Contáctanos
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
