import React, { useState } from 'react';
import db from '../services/firestore'; // Asegúrate de tener la importación correcta de Firestore
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics"; // Importamos funciones de Analytics

function Unsubscribe() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleUnsubscribe = async (e) => {
    e.preventDefault();
    setMessage(''); // Reiniciar el mensaje

    // Convertir a minúsculas
    const lowerCaseEmail = email.toLowerCase();

    try {
      // Buscar el correo en la colección "companies"
      const q = query(collection(db, 'companies'), where('email', '==', lowerCaseEmail));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage('No se encontró ninguna compañía con el correo proporcionado.');
        return;
      }

      // Actualizar el estado "status" a false
      querySnapshot.forEach(async (docSnap) => {
        await updateDoc(docSnap.ref, {
          status: false,
        });
      });

      // Registrar el evento en Google Analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'empresa_dada_de_baja'); // Evento personalizado en español

      setMessage('Su suscripción ha sido cancelada exitosamente. No volveremos a enviarte más correos.');
      setEmail(''); // Limpia el input de correo después de la baja exitosa

    } catch (error) {
      console.error('Error al cancelar la suscripción:', error);
      setMessage('Se produjo un error al cancelar la suscripción. Por favor, intenta de nuevo.');
    }
  };

  return (
    <div className="page-content-padding">
      <section className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6" style={{ paddingTop: '70px' }}>

              <div className="card shadow">
                <div className="card-body">
                  <h3 className="text-center mb-4">Darme de baja de esta lista</h3>
                  {message && <div className={`alert ${message.includes('error') ? 'alert-danger' : 'alert-success'} text-center`}>{message}</div>}
                  <form onSubmit={handleUnsubscribe}>
                    <div className="form-group">
                      <label htmlFor="email">Correo electrónico</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Ingresa tu correo"
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">Dar de baja</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Unsubscribe;
