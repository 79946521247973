import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import db from '../services/firestore'; // Importamos Firestore
import { getAnalytics, logEvent } from "firebase/analytics"; // Importamos funciones de Analytics

const Contact = () => {
  const [form, setForm] = useState({ email: '', message: '', url: '' });
  const [result, setResult] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false); // Estado para saber si ya se envió el formulario

  // Función para limpiar caracteres maliciosos
  const sanitizeInput = (input) => {
    const pattern = /[<>]/g; // Evitar caracteres que puedan ser utilizados para inyecciones XSS
    return input.replace(pattern, '');
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: sanitizeInput(e.target.value) }); // Limpiamos la entrada del usuario
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evita la recarga de la página

    // Validar manualmente si el email tiene un formato válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email)) {
      setResult('Por favor ingresa un correo electrónico válido.');
      return;
    }

    // Validar si el campo mensaje está vacío
    if (!form.message.trim()) {
      setResult('El campo de mensaje es obligatorio.');
      return;
    }

    // Validar si el campo mensaje contiene caracteres peligrosos
    if (form.message.length > 1000) {
      setResult('El mensaje no puede exceder los 1000 caracteres.');
      return;
    }

    try {
      // Guardar los datos del formulario en la colección "contacts"
      await addDoc(collection(db, 'contacts'), {
        email: form.email,
        message: sanitizeInput(form.message), // Sanitizamos el mensaje para evitar inyecciones de código
        url: form.url || null,  // Esto es opcional si quieres guardar el campo de antispam
        createdAt: new Date()   // Guardar la fecha de envío
      });

      // Registrar el evento en Google Analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'mensaje_enviado'); // Evento personalizado en español

      setIsSubmitted(true); // Cambiar el estado para mostrar el mensaje de éxito
    } catch (error) {
      console.error('Error al enviar el mensaje: ', error);
      // Mostrar el mensaje de error en español dependiendo del tipo de error
      if (error.code === 'permission-denied') {
        setResult('No tienes permiso para enviar este mensaje.');
      } else if (error.code === 'network-request-failed') {
        setResult('Error de red. Verifica tu conexión a Internet.');
      } else {
        setResult('Ocurrió un error al enviar tu mensaje. Inténtalo de nuevo más tarde.');
      }
    }
  };

  return (
    <section id="contact" className="dtr-section dtr-py-100">
      <div className="container">
        {/* heading */}
        <div className="row dtr-mb-50">
          <div className="col-12 text-center">
            <h2 className="color-green">Conéctate con Nosotros</h2>
            <p style={{ fontSize: '18px', color: '#555' }}>
              Estamos aquí para ayudarte a transformar tus ideas en soluciones
              tecnológicas tangibles que generen valor y ventajas competitivas.
            </p>
            <div className="dtr-styled-divider divider-center bg-light-green"></div>
          </div>
        </div>
        {/* content */}
        <div className="row">
          {/* image */}
          <div className="col-12 col-md-5 dtr-md-mb-30">
            <img
              src="/assets/images/contact-img.png"
              alt="Conéctate con Nosotros"
              style={{ borderRadius: '10px' }}
            />
          </div>
          {/* form */}
          <div className="col-12 col-md-7">
            {isSubmitted ? (
              <div className="alert alert-success">
                Tu mensaje ha sido enviado exitosamente. Nos pondremos en contacto contigo pronto.
              </div>
            ) : (
              <div className="dtr-form dtr-form-has-icon">
                <form id="contactform" onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="email" style={{ fontSize: '14px' }}>
                        Correo Electrónico:
                      </label>
                      <p className="dtr-input">
                        <i className="icon-envelope1 color-dark-blue"></i>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="usuario@gmail.com"
                          value={form.email}
                          onChange={handleChange}
                          required
                        />
                      </p>
                    </div>
                    <p className="antispam" style={{ display: 'none' }}>
                      Deja este campo vacío:
                      <br />
                      <input
                        name="url"
                        value={form.url}
                        onChange={handleChange}
                      />
                    </p>
                    <div className="form-group">
                      <label htmlFor="message" style={{ fontSize: '14px' }}>
                        Mensaje:
                      </label>
                      <p className="dtr-input">
                        <i className="icon-user-edit color-dark-blue"></i>
                        <textarea
                          rows="4"
                          name="message"
                          id="message"
                          placeholder="Escribe tu mensaje aquí"
                          value={form.message}
                          onChange={handleChange}
                          required // Campo requerido
                        ></textarea>
                      </p>
                    </div>
                    <p className="dtr-m-0">
                      <button
                        className="dtr-btn btn-dark-blue"
                        type="button" // Cambiamos de submit a button
                        onClick={handleSubmit} // Llamamos manualmente al handleSubmit
                        style={{
                          backgroundColor: '#28A745',
                          borderRadius: '5px',
                          padding: '10px 20px',
                        }}
                      >
                        Enviar Mensaje
                      </button>
                    </p>
                    <div id="result" style={{ color: 'red' }}>{result}</div>
                  </fieldset>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
